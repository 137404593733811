.page-container {
    max-width: 840px;
}

.outlined {
    border: 2px solid #888;
    border-radius: 10px;
}

.scrollable {
    overflow: auto
}
.scrollable::-webkit-scrollbar {
  width: 6px;
}
.scrollable::-webkit-scrollbar-thumb {
  background-color: transparent;
}

.modal {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 24;
  backdrop-filter: blur(6px);
}
